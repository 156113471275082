export interface IShopItem {
  id: number;
  platform: ShopPlatform;
  shop_name: string;
  currency_code: string | null;
}

export enum ShopPlatform {
  Etsy = "etsy"
  // Shopify = "shopify"
}
