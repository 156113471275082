import React, { useMemo } from "react";

import { Button } from "reactstrap";

import { ShopPlatform } from "../../common/interfaces/integrations.interface";

interface Props {
  platform: ShopPlatform;
  onConnectClick: (platform: ShopPlatform) => void;
}

export const NewShopItem: React.FC<Props> = ({ platform, onConnectClick }) => {
  const logoUrl = useMemo(() => {
    switch (platform) {
      case ShopPlatform.Etsy:
        return "/assets/integration/platforms/etsy.png";
      // case ShopPlatform.Shopify:
      //   return "/assets/integration/platforms/shopify.png";
      default:
        return `/assets/integration/platforms/${platform}.png`;
    }
  }, [platform]);

  const copyRight = useMemo(() => {
    switch (platform) {
      case ShopPlatform.Etsy:
        return "’Etsy’ is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.";
      // case ShopPlatform.Shopify:
      //   return null;
      default:
        return null;
    }
  }, [platform]);

  return (
    <div className="shop-item">
      <div className="shop-item__name">Connect {platform} shop</div>
      <div className="shop-item__logo">
        <i className="bx bx-link" />
        <img src={logoUrl} alt={platform} />
      </div>
      {copyRight && <div className="shop-item__copyright">{copyRight}</div>}
      <div className="shop-item__actions">
        <Button
          color="primary"
          onClick={() => {
            onConnectClick(platform);
          }}
        >
          Connect
        </Button>
      </div>
    </div>
  );
};
