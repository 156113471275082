import React from "react";

const items = [
  {
    date: "October 31st",
    name: "Halloween"
  },
  {
    date: "December 24th",
    name: "Christmas"
  },
  {
    date: "January 1st",
    name: "New Year"
  }
];

export const HolidaysWidget = () => {
  return (
    <div className="holidays-widget">
      <div className="holidays-widget__left">
      {/*  <div className="holidays-widget__title">Next Holidays</div>*/}
      {/*  /!* <table className="holidays-widget__list"> *!/*/}
      {/*  /!*  {items.map((item, index) => ( *!/*/}
      {/*  /!*    <tr className="holidays-widget__list__item" key={index}> *!/*/}
      {/*  /!*      <td className="holidays-widget__list__item__date"> *!/*/}
      {/*  /!*        <i className="mdi mdi-calendar-blank-outline" /> *!/*/}
      {/*  /!*        {item.date} *!/*/}
      {/*  /!*      </td> *!/*/}
      {/*  /!*      <td className="holidays-widget__list__item__name">{item.name}</td> *!/*/}
      {/*  /!*    </tr> *!/*/}
      {/*  /!*  ))} *!/*/}
      {/*  /!* </table> *!/*/}
      {/*  <div className="holidays-widget__btn">*/}
      {/*    <a href="/assets/files/calendar.pdf" target="_blank" className="btn btn-primary" rel="noreferrer">*/}
      {/*      View calendar*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="holidays-widget__title">Product Prices</div>
      {/* <table className="holidays-widget__list"> */}
      {/*  {items.map((item, index) => ( */}
      {/*    <tr className="holidays-widget__list__item" key={index}> */}
      {/*      <td className="holidays-widget__list__item__date"> */}
      {/*        <i className="mdi mdi-calendar-blank-outline" /> */}
      {/*        {item.date} */}
      {/*      </td> */}
      {/*      <td className="holidays-widget__list__item__name">{item.name}</td> */}
      {/*    </tr> */}
      {/*  ))} */}
      {/* </table> */}
      <div className="holidays-widget__btn">
        <a href=" https://docs.google.com/spreadsheets/u/2/d/1xKYxJt7de0JegVjFcIcOZFDUhOQ_ZVpa/edit#gid=178459868" target="_blank" className="btn btn-primary" rel="noreferrer">
          VIEW PRICES
        </a>
      </div>
    </div>

      <div className="holidays-widget__right">
        <img src="/assets/images/product_shipping.png" alt="content" />
      </div>
    </div>
  );
};
