import React, { useEffect, useMemo, useState } from "react";

import "./style/design-style.scss";

import { Node, NodeConfig } from "konva/lib/Node";
import { nanoid } from "nanoid";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useHotkeys } from "react-hotkeys-hook";
import { Transformer } from "react-konva";

import hotkeyList from "./config/hotkey.json";
import workModeList from "./config/workMode.json";
import useHotkeyFunc from "./hook/useHotkeyFunc";
import useI18n from "./hook/usei18n";
import useItem from "./hook/useItem";
import useModal from "./hook/useModal";
import useSelection from "./hook/useSelection";
import useStage from "./hook/useStage";
import useStageDataList from "./hook/useStageDataList";
import useTab from "./hook/useTab";
import useTool from "./hook/useTool";
import useTransformer from "./hook/useTransformer";
import useWorkHistory from "./hook/useWorkHistory";
import Layout from "./layout";
import NavBar from "./navBar";
import NavBarButton from "./navBar/NavBarButton";
import SettingBar from "./settingBar";
import TabGroup from "./tab";
import View from "./view";
import Frame, { FrameProps } from "./view/frame";
import IconItem, { IconItemProps } from "./view/object/icon";
import ImageItem, { ImageItemProps } from "./view/object/image";
import LineItem, { LineItemProps } from "./view/object/line";
import ShapeItem, { ShapeItemProps } from "./view/object/shape";
import TextItem, { TextItemProps } from "./view/object/text";
import { StageData } from "../store/canvasImageEditor/currentStageData";
import { initialStageDataList } from "../store/canvasImageEditor/initilaStageDataList";
import { StageDataListItem } from "../store/canvasImageEditor/StageDataList";
import { CanvasEditorDropZone } from "./dropzone/CanvasEditorDropzone";
import { IDesignItem, ProductAssetType, ProductType } from "../common/interfaces/products.interface";

export type FileKind = {
  "file-id": string;
  title: string;
  data: Record<string, any>[];
};

export type FileData = Record<string, FileKind>;

interface Props {
  initialDesignName: string;
  onSubmit: (data: File | Blob, title: string) => void;
  onCancel: () => void;
  printfileSize: [number, number] // [width, height]
  copyItemTarget?: IDesignItem | null;
  assetSrc?: string;
}

const CanvasEditor: React.FC<Props> = ({copyItemTarget, initialDesignName, onSubmit, onCancel, assetSrc, printfileSize}) => {
  const [past, setPast] = useState<StageData[][]>([]);
  const [future, setFuture] = useState<StageData[][]>([]);
  const { goToFuture, goToPast, recordPast, clearHistory } = useWorkHistory(past, future, setPast, setFuture);
  const transformer = useTransformer();
  const { selectedItems, onSelectItem, setSelectedItems, clearSelection } = useSelection(transformer);
  const { tabList, onCreateTab } = useTab(transformer, clearHistory);
  const { stageData } = useItem();
  const { initializeFileDataList, updateFileData } = useStageDataList();
  const stage = useStage();
  const modal = useModal();
  const {
    deleteItems,
    copyItems,
    selectAll,
    pasteItems,
    duplicateItems,
    layerDown,
    layerUp,
    flipHorizontally,
    flipVertically,
    alignCenter,
    move
  } = useHotkeyFunc();
  const { getTranslation } = useI18n();
  const [clipboard, setClipboard] = useState<StageData[]>([]);
  const createStageDataObject = (item: Node<NodeConfig>): StageData => {
    const { id } = item.attrs;
    const target = item.attrs["data-item-type"] === "frame" ? item.getParent() : item;
    return {
      id: nanoid(),
      attrs: {
        ...(stageData.find((_item) => _item.attrs.id === id)?.attrs ?? {})
      },
      className: target.getType(),
      children: []
    };
  };
  const { getClickCallback } = useTool(
    stage,
    modal,
    selectedItems,
    setSelectedItems,
    transformer,
    createStageDataObject,
    onSelectItem
  );

  const currentTabId = useMemo(() => tabList.find((tab) => tab.active)?.id ?? null, [tabList]);

  const sortedStageData = useMemo(() => stageData, [stageData]);

  const navBar = (
    <NavBar>
      {workModeList.map((data) => (
        <NavBarButton key={`navbar-${data.id}`} data={data} stage={stage} onClick={getClickCallback(data.id)} />
      ))}
    </NavBar>
  );

  const hotkeyModal = (
    <Modal show={modal.displayModal} onHide={modal.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Keyboard Shortcut</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hotkeyList.map((hotkey) => (
          <Col key={hotkey.name}>
            <h6>{getTranslation("hotkey", hotkey.id, "name")}</h6>
            <Row className="justify-content-end" xs={4}>
              {hotkey.keys.map((key, idx) => (
                <React.Fragment key={hotkey.name + key}>
                  {idx !== 0 && "+"}
                  <Col xs="auto" className="align-items-center">
                    <Button disabled>{key}</Button>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Col>
        ))}
      </Modal.Body>
    </Modal>
  );

  const settingBar = (
    <SettingBar printfileSize={printfileSize} onCancel={onCancel} onSubmit={onSubmit} initialDesignName={initialDesignName} selectedItems={selectedItems} clearSelection={clearSelection} stageRef={stage.stageRef} />
  );

  const renderObject = (item: StageData) => {
    switch (item.attrs["data-item-type"]) {
      case "frame":
        return <Frame key={`frame-${item.id}`} data={item as FrameProps["data"]} onSelect={onSelectItem} />;
      case "image":
        return <ImageItem key={`image-${item.id}`} data={item as ImageItemProps["data"]} onSelect={onSelectItem} />;
      case "text":
        return (
          <TextItem
            key={`image-${item.id}`}
            data={item as TextItemProps["data"]}
            transformer={transformer}
            onSelect={onSelectItem}
          />
        );
      case "shape":
        return (
          <ShapeItem
            key={`shape-${item.id}`}
            data={item as ShapeItemProps["data"]}
            transformer={transformer}
            onSelect={onSelectItem}
          />
        );
      case "icon":
        return (
          <IconItem
            key={`icon-${item.id}`}
            data={item as IconItemProps["data"]}
            transformer={transformer}
            onSelect={onSelectItem}
          />
        );
      case "line":
        return (
          <LineItem
            key={`line-${item.id}`}
            data={item as LineItemProps["data"]}
            transformer={transformer}
            onSelect={onSelectItem}
          />
        );
      default:
        return null;
    }
  };

  useHotkeys(
    "shift+up",
    (e) => {
      e.preventDefault();
      layerUp(selectedItems);
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "shift+down",
    (e) => {
      e.preventDefault();
      layerDown(selectedItems);
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "ctrl+d",
    (e) => {
      e.preventDefault();
      duplicateItems(selectedItems, createStageDataObject);
    },
    {},
    [selectedItems, stageData]
  );

  useHotkeys(
    "ctrl+c",
    (e) => {
      e.preventDefault();
      copyItems(selectedItems, setClipboard, createStageDataObject);
    },
    {},
    [selectedItems, stageData, clipboard]
  );

  useHotkeys(
    "ctrl+a",
    (e) => {
      e.preventDefault();
      selectAll(stage, onSelectItem);
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "ctrl+v",
    (e) => {
      e.preventDefault();
      pasteItems(clipboard);
    },
    {},
    [clipboard]
  );

  useHotkeys(
    "ctrl+z",
    (e) => {
      e.preventDefault();
      goToPast();
    },
    {},
    [goToPast]
  );

  useHotkeys(
    "ctrl+y",
    (e) => {
      e.preventDefault();
      goToFuture();
    },
    {},
    [goToFuture]
  );

  useHotkeys(
    "shift+h",
    (e) => {
      e.preventDefault();
      flipHorizontally(selectedItems);
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "shift+v",
    (e) => {
      e.preventDefault();
      flipVertically(selectedItems);
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "left",
    (e) => {
      e.preventDefault();
      move(selectedItems, "left");
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "right",
    (e) => {
      e.preventDefault();
      move(selectedItems, "right");
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();
      move(selectedItems, "up");
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();
      move(selectedItems, "down");
    },
    {},
    [selectedItems]
  );

  useHotkeys(
    "backspace, delete",
    (e) => {
      e.preventDefault();
      deleteItems(selectedItems, setSelectedItems, transformer.transformerRef);
    },
    { enabled: Boolean(selectedItems.length) },
    [selectedItems, transformer.transformerRef.current]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      e.returnValue = "";
    });
    onCreateTab(undefined, initialStageDataList[0] as StageDataListItem);
    initializeFileDataList(initialStageDataList);
    stage.stageRef.current.setPosition({
      x: Math.max(Math.ceil(stage.stageRef.current.width() - 1280) / 2, 0),
      y: Math.max(Math.ceil(stage.stageRef.current.height() - 760) / 2, 0)
      // x: Math.max(Math.ceil(stage.stageRef.current.width() - printfileSize[0]) / 2, 0),
      // y: Math.max(Math.ceil(stage.stageRef.current.height() - printfileSize[1]) / 2, 0)
    });
    stage.stageRef.current.batchDraw();
  }, []);

  useEffect(() => {
    if (currentTabId) {
      updateFileData({
        id: currentTabId,
        data: stageData
      });
    }
    recordPast(stageData);
  }, [stageData]);

  return (
    <Layout navBar={navBar} settingBar={settingBar}>
      {hotkeyModal}
      <CanvasEditorDropZone stage={stage} initiallyHide={!!copyItemTarget} printfileSize={printfileSize}>
        <View
          assetSrc={assetSrc}
          printfileSize={printfileSize}
          onSelect={onSelectItem}
          stage={stage}
          copyItemTarget={copyItemTarget}
          firstLayer={
            sortedStageData.length
              ? renderObject(sortedStageData.filter((item) => item.attrs["data-item-type"] === "frame")[0])
              : undefined
          }
          secondLayer={
            sortedStageData.length
              ? sortedStageData
                .filter((item) => item.attrs["data-item-type"] !== "frame")
                .map((item) => renderObject(item))
              : undefined
          }
        >
          <Transformer
            ref={transformer.transformerRef}
            keepRatio
            shouldOverdrawWholeArea
            boundBoxFunc={(_, newBox) => newBox}
            onTransformEnd={transformer.onTransformEnd}
          />
        </View>
      </CanvasEditorDropZone>
    </Layout>
  );
}

export default CanvasEditor;
