import { IBaseApiResponse, IPaginationMeta } from "../../common/interfaces/api.interface";
import { IShopItem, ShopPlatform } from "../../common/interfaces/integrations.interface";
import BaseAPIService from "./BaseApiService";

class IntegrationApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api/ecommerce`);
  }

  public async getConnectedShopList(): Promise<IShopItem[]> {
    const res = await this.api.get<IBaseApiResponse<IShopItem[]>>(`/shops`);

    return res.data.data;
  }

  public async deleteShopById(shopId: number): Promise<void> {
    await this.api.delete(`/shops/${shopId}`);
  }

  public async connectShop(platform: ShopPlatform, shopUrl: string): Promise<void> {
    // if(platform === 'shopify'){
    //   const res = await this.api.get<{
    //     data: {
    //       url: string;
    //     };
    //   }>(`/${platform}/oauth`);
    //   window.location.href = res.data.data.url;
    // }
    // else{
      const res = await this.api.get<{
        data: {
          url: string;
        };
      }>(`/${platform}/oauth`);
      window.location.href = res.data.data.url;
    // }
  }
}

const instance = new IntegrationApiService();

export default instance;
