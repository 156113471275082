import React from "react";

import { Button } from "reactstrap";

export const HelpWidget = () => {
  return (
    <div className="help-widget">
      <div className="help-widget__title">Need some help?</div>
      <div className="help-widget__description">Book a 15-minute conversation.</div>
      <div className="help-widget__button">
        <a
          href="https://tidycal.com/alerioprint/15-minute-meeting"
          target="_blank"
          className="btn btn-primary"
          rel="noreferrer"
        >
          Book conversation
        </a>
      </div>
    </div>
  );
};
